import React, { Component } from 'react';

class Quiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {},
            fields: {
                fullName: null,
                age: null,
                email: null,
                phone: null,
                contact_me: null,
                quizScore: null,
            },
            errors: {
                fullName: 'Your Name is required!',
                age: 'Your Age is required!',
                email: "Valid Email is required!",
                phone: 'Valid Phone is required!',
                quizNotFilled: 'Please answer all the questions',
            },
            hasErrors: false,
        };
    }


    handleOptionChange = (event) => {
        const { name, value } = event.target;
        let answers = this.state.answers;
        answers[name] = value;
        this.setState({ answers, [name]: value });
        if (Object.values(answers).length === 10) {
            let answersError = this.state.errors
            answersError.quizNotFilled = ''
        }

    };

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const validEmailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/)
        const validPhoneRegex = new RegExp(/((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/)
        let errors = this.state.errors;
        let fields = this.state.fields;

        switch (name) {
            case 'fullName':
                errors.fullName = value.length > 0 && '';
                fields.fullName = value;
                break;
            case 'age':
                errors.age = value.length > 0 && '';
                fields.age = value;
                break;
            case 'email':
                errors.email = (validEmailRegex.test(value) ? '' : 'Valid Email is required!');
                fields.email = value;
                break;
            case 'phone':
                errors.phone = (validPhoneRegex.test(value) ? '' : 'Valid Phone is required!');
                fields.phone = value;
                break;
            default:
                break;
        }
        this.setState({ errors, fields, [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm(this.state.errors)) {
            const answersArr = Object.values(this.state.answers);
            answersArr.pop()
            const quizScore = answersArr.reduce((a, b) => (parseInt(a) + parseInt(b)));
            const storage = typeof window === 'undefined' ? null : window.sessionStorage;
            if (storage) {
                storage.setItem('score', `${quizScore}`)
                storage.setItem(`name`, `${this.state.fields.fullName}`)
            }
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "Quiz", ...this.state })
            })
                .then(() => {
                    window.dataLayer && window.dataLayer.push({ "event": "quizSubmitted" });
                    window.location.pathname = "/formSubmitted"
                })
                .catch(error => alert(error));
        } else {
            this.setState({ hasErrors: true })
        }
    }

    render() {
        const { hasErrors, errors, answers } = this.state;
        return (
            <div className="jumbotron jumbotron-fluid with-shadow quiz-wrapper">
                <div className='d-flex flex-column align-items-center'>

                    <div>
                        <h1 className="is-purple font-weight-bold m-0 pb-4 text-center">Neurological Health Quiz</h1>
                        <form name="Quiz" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit} >
                            <input type="hidden" name="Quiz" value="contact" />
                            <div className="container">
                                <div className='row border-bottom mt-2 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">I do things slowly</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question1'
                                                checked={answers.question1 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question1'
                                                checked={answers.question1 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question1'
                                                checked={answers.question1 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question1'
                                                checked={answers.question1 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">My future seems hopeless</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question2'
                                                checked={answers.question2 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question2'
                                                checked={answers.question2 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question2'
                                                checked={answers.question2 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question2'
                                                checked={answers.question2 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">It is hard for me to concentrate on reading</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question3'
                                                checked={answers.question3 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question3'
                                                checked={answers.question3 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question3'
                                                checked={answers.question3 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question3'
                                                checked={answers.question3 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">I have difficulty making decisions</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question4'
                                                checked={answers.question4 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question4'
                                                checked={answers.question4 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question4'
                                                checked={answers.question4 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question4'
                                                checked={answers.question4 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">I have lost interest in aspects of my life that used to be important to me</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question5'
                                                checked={answers.question5 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question5'
                                                checked={answers.question5 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question5'
                                                checked={answers.question5 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question5'
                                                checked={answers.question5 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">I feel sad, blue, and unhappy</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question6'
                                                checked={answers.question6 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question6'
                                                checked={answers.question6 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question6'
                                                checked={answers.question6 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question6'
                                                checked={answers.question6 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">I am agitated and keep moving around</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question7'
                                                checked={answers.question7 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question7'
                                                checked={answers.question7 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question7'
                                                checked={answers.question7 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question7'
                                                checked={answers.question7 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">I feel fatigued</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question8'
                                                checked={answers.question8 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question8'
                                                checked={answers.question8 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question8'
                                                checked={answers.question8 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question8'
                                                checked={answers.question8 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6">
                                        <p className="is-purple font-size-18 font-weight-bold">I sleep with interruptions, too much or too little</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question9'
                                                checked={answers.question9 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question9'
                                                checked={answers.question9 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question9'
                                                checked={answers.question9 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question9'
                                                checked={answers.question9 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">I don’t feel much joy even when good things happen to me</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question10'
                                                checked={answers.question10 === "10"}
                                                onChange={this.handleOptionChange}
                                                value="10"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question10'
                                                checked={answers.question10 === "6"}
                                                onChange={this.handleOptionChange}
                                                value="6"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question10'
                                                checked={answers.question10 === "4"}
                                                onChange={this.handleOptionChange}
                                                value="4"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='question10'
                                                checked={answers.question10 === "2"}
                                                onChange={this.handleOptionChange}
                                                value="2"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold">Would you like us to contact you for a FREE Consultation?</p>
                                    </div>
                                    <div className="col-sm d-flex align-items-center">
                                        <div className="d-flex is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="consultation_request">Yes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='consultation_request'
                                                checked={answers.consultation_request === "Yes"}
                                                onChange={this.handleOptionChange}
                                                value="Yes"

                                            /></div>
                                            <label className="is-gray font-size-12 ml-2" htmlFor="consultation_request">No</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='consultation_request'
                                                checked={answers.consultation_request === "No"}
                                                onChange={this.handleOptionChange}
                                                value="No"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-1 mb-1 text-center">
                                    {errors.quizNotFilled.length > 0 && hasErrors &&
                                        <span className='error pt-1 pb-1'>{errors.quizNotFilled}</span>}
                                </div>
                                <div className='row mt-4 justify-content-start'>
                                    <div className="col">
                                        <div className="d-flex flex-column mb-2 is-purple">
                                            <label htmlFor="fullName">Your Name</label>
                                            <input id="fullName" className="p-2" placeholder="e.g. John Doe" type='text' name='fullName' onChange={this.handleChange} noValidate />
                                            {errors.fullName.length > 0 && hasErrors &&
                                                <span className='error'>{errors.fullName}</span>}
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-5">
                                        <div className="d-flex flex-column mb-2 is-purple">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input id="lastName" className="p-2" placeholder="e.g. Doe" type='text' name='lastName' onChange={this.handleChange} noValidate />
                                            {errors.lastName.length > 0 && hasErrors &&
                                                <span className='error'>{errors.lastName}</span>}
                                        </div>
                                    </div> */}
                                    <div className="col">
                                        <div className="d-flex flex-column mb-2 is-purple">
                                            <label htmlFor="age">Age</label>
                                            <input className="p-2" placeholder="e.g. 35" type='text' name='age' onChange={this.handleChange} noValidate />
                                            {errors.age.length > 0 && hasErrors &&
                                                <span className='error'>{errors.age}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col">
                                        <div className="d-flex flex-column mb-2 is-purple">
                                            <label htmlFor="email">Email</label>
                                            <input className="p-2" placeholder="e.g. john@email.com" type='email' name='email' onChange={this.handleChange} noValidate />
                                            {errors.email.length > 0 && hasErrors &&
                                                <span className='error'>{errors.email}</span>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="d-flex flex-column mb-2 is-purple">
                                            <label htmlFor="phone">Phone</label>
                                            <input type='phone' name='phone' className="p-2" placeholder="e.g. 1231231234" onChange={this.handleChange} />
                                            {(errors.phone.length > 0) && hasErrors &&
                                                <span className='error'>{errors.phone}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mb-2 mt-3">
                                    <button className="submit">See Your Results!</button>
                                </div>
                                <p className="is-purple p-2 text-center font-size-18 info-privacy">Your information is 100% confidential and will never be shared with anyone</p>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        );
    }
}

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

export default Quiz