import React from "react"
import SEO from "../components/seo"
import Quiz from "../components/Quiz"


const QuizPage = () => (
  <>
    <SEO title="Nurological quiz" />
    <div className="hero-wrapper quiz" >
        <div style={{ margin: '0 auto', padding: '0rem 1rem'}}>
        < Quiz/>
        </div>
    </div>
  </>
)

export default QuizPage
